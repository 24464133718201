
import search from '~/mixins/search'

export default {
  name: 'HeaderSearchResults',
  mixins: [
    search,
  ],

  props: {
    query: {
      type: String,
      required: true,
    },
  },

  computed: {
    products () {
      return [...this.$search.products()].splice(0, 5)
    },

    productsGroup () {
      return this.products.reduce((acc, product) => {
        acc[product.mappedProductType] = acc[product.mappedProductType] || []
        acc[product.mappedProductType].push(product)
        return acc
      }, {})
    },

    isSearchPage () {
      return /\/search\/q/.test(this.$route.path)
    },
  },

  methods: {
    clickedOnProduct () {
      this.$emit('hide-results', 'clickedOnProduct')
    },

    boldText (text) {
      const regExp = new RegExp(this.query, 'gi')

      return text.replace(regExp, '<strong>$&</strong>')
    },

    // @todo remove after BE fix
    removeHost (url) {
      return `${url.substring(url.indexOf(/p/))}`
    },
  },
}
