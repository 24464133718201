
import { debounce } from 'debounce'

export default {
  name: 'HeaderSearch',
  props: {
    isResultDisabled: {
      type: Boolean,
      default: false,
    },

    isArticle: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: 'Search',
    },

    placeholderShop: {
      type: String,
      default: '',
    },

    fitToCategoryContent: {
      type: Boolean,
      default: false,
    },

    isHomeComponent: {
      type: Boolean,
      default: false,
    },

    version: {
      type: String,
      default: '1',
    },
  },

  data () {
    return {
      query: this.$route.query?.q || '',
      page: this.$route.query?.page || 1,
      isOpen: false,
    }
  },

  watch: {
    query () {
      /\/search\/q/.test(this.$route.fullPath)
        ? this.updateRoute(this.query)
        : this.getSearchResults(this.query)
    },
  },

  methods: {
    updateRoute: debounce(function (q) {
      const query = { q }

      if (this.$route.query.sort) {
        query.sort = this.$route.query.sort
      }

      if (this.$route.query.size) {
        query.size = this.$route.query.size
      }

      if (q.length >= 3) {
        this.$router.push({
          path: this.localePath('/search/q'),
          query,
        })
      }
    }, 300),

    getSearchResults: debounce(function (query) {
      this.$store.dispatch('search/getSearchResults',
        {
          query,
          page: +this.$route.query?.page || 1,
          sort: this.$route.query?.sort,
        },
      )
    }, 300),

    onResultsOpen () {
      if (this.isResultDisabled) {
        return
      }

      this.isOpen = true
      this.$nextTick(() => {
        if (this.$css.breakpoints.smAndDown) {
          this.$refs['input-mobile'].$refs.input.focus()
        }
      })
    },

    onResultsClose () {
      this.isOpen = false
    },

    onSubmit (query) {
      this.updateRoute(query)
      this.onResultsClose()
    },
  },
}
