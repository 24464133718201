let uidCounter = 0

export default {
  beforeCreate () {
    uidCounter += 1
    this.uid = `uid-${uidCounter}`
  },

  methods: {
    $id (id) {
      return `${this.uid}-${id}`
    },
  },
}
